import { DateTime } from 'luxon'

module Time {

  export const formatDate = (date: string): string => {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)
  }

  export const formatTime = (time: string): string => {
    return DateTime.fromISO(time).toLocaleString(DateTime.TIME_24_SIMPLE)
  }

  export const formatCardExpiry = (month: number, year: number): string => {
    return String(month).padStart(2, '0') + '/' + year.toString()
  }
}

export default Time
