import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import { type FC, type MouseEventHandler } from 'react'
import { type FridayGivingDTO } from '@shamaazi/mytennights'
import Time from '~/service/time'

interface FGScheduleOptionsCardProps {
  donorId: string
  schedule: FridayGivingDTO
  scheduleId: string
  toggleCancellation: MouseEventHandler
}

export const FGScheduleOptionsCard: FC<FGScheduleOptionsCardProps> = ({ schedule, toggleCancellation }) => {
  const cardExpiry = schedule.donor_details.card_expiry_year && schedule.donor_details.card_expiry_month
    ? <><strong>Expires: </strong>{Time.formatCardExpiry(schedule.donor_details.card_expiry_month, schedule.donor_details.card_expiry_year)}</>
    : <></>

  return (
        <> <Card className="my-3 rounded shadow-lg">
            <ListGroup className="list-group-flush">
                <ListGroupItem>
                    <strong>Paying with: </strong>{schedule.donor_details.card_brand} ending {schedule.donor_details.last_4_card_digits}
                    <br />
                    {cardExpiry}
                </ListGroupItem>
                <ListGroupItem>
                    Opted
                    <strong>{schedule.gift_aid ? ' ' : ' not '}</strong>
                    to gift aid the donation
                </ListGroupItem>

                {<ListGroupItem>
                    <Button variant="outline-danger" onClick={toggleCancellation}>
                        {schedule.cancelled ? 'Reactivate Friday Giving Schedule' : 'Cancel/Pause Friday Giving Schedule'}
                    </Button>
                </ListGroupItem> }
            </ListGroup>
        </Card>
        </>
  )
}
