import { type FC } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import FormSelect from 'react-bootstrap/FormSelect'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { type APIDonorDetails } from '@shamaazi/mytennights'
import { ScheduleAPI } from '~/service/schedule'
import { allCountries } from '@lib/services'
import updateDonorDetails = ScheduleAPI.updateDonorDetails

export const UpdateDonorModal: FC<{ show: boolean, donorId: string, scheduleId: string, donorDetails: APIDonorDetails, handleClose: () => void }> =
  ({ show, donorId, scheduleId, donorDetails, handleClose }) => {
    interface UpdateDonorFormData extends APIDonorDetails {
      ticket: string
    }

    const { register, handleSubmit, formState: { errors } } = useForm<UpdateDonorFormData>({
      defaultValues: {
        ...donorDetails,
        ticket: ''
      }
    })

    const { mutate: submit, isLoading, isError } = useMutation(async (data: UpdateDonorFormData): Promise<void> => {
      await updateDonorDetails(donorId, scheduleId, {
        ticket_number: data.ticket,
        donor_details: data
      }
      )
    }, {
      onSuccess: () => {
        handleClose()
      }
    })

    function getFormControl (name: string, id: keyof UpdateDonorFormData, control: JSX.Element): JSX.Element {
      return <>
        <Form.Label>{name}</Form.Label>
        {control}
        <p className="mt-2 text-xs text-right text-mtn-red">{errors[id]?.message ?? ''}</p>
      </>
    }

    function getFormInput (id: keyof UpdateDonorFormData, name: string, type: string = 'text'): JSX.Element {
      return getFormControl(name, id, <Form.Control
          id={id}
          type={type}
          placeholder={name}
          {...register(id, { required: `Please enter ${name}` })}
        />
      )
    }

    return <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Donor Details</Modal.Title>
      </Modal.Header>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Form onSubmit={handleSubmit(data => { submit(data) })}>
        <Modal.Body>
          {getFormInput('first_name', 'First Name')}
          {getFormInput('last_name', 'Last Name')}
          {
            getFormControl('Country', 'country', <FormSelect
              id="country"
              {...register('country')}>
              {allCountries.map(c => <option id={c.code} value={c.code}>{c.name}</option>)}
            </FormSelect>)
          }
          {getFormInput('address', 'Address')}
          {getFormInput('house_number', 'House Number')}
          {getFormInput('postcode', 'Post Code')}
          {getFormInput('ticket', 'Ticket Number')}
        </Modal.Body>
        {isError && <p className="text-sm text-right text-sz-red">Oops! Something went wrong saving this info</p>}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button disabled={isLoading} variant="primary" type="submit">
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  }
