import { useState } from 'react'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'

export default ({ emailSubmitted, error, onEmail, onLoginCode }) => {
  const [email, setEmailInput] = useState('')
  const [loginCode, setLoginCodeInput] = useState('')

  const onSubmit = e => {
    e.preventDefault()
    if (emailSubmitted) {
      onLoginCode(loginCode)
    } else {
      onEmail(email)
    }
  }

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h1>Login</h1>
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={e => setEmailInput(e.target.value)}
                readOnly={emailSubmitted}
              />
            </Form.Group>
            {emailSubmitted && (
              <>
                <Alert variant="info">Please check your email for a login code.</Alert>
                <Form.Group controlId="formLoginCode">
                  <Form.Control
                    type="loginCode"
                    placeholder="Login code"
                    value={loginCode}
                    onChange={e => setLoginCodeInput(e.target.value)}
                  />
                </Form.Group>
              </>
            )}
            {error && <Alert variant="danger">{error}</Alert>}
            <Button
              variant="primary"
              type="submit"
              disabled={emailSubmitted ? !loginCode : !email}
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
