import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import { isMyTenNights, ScheduleAPI } from '~/service/schedule'
import { savePDF, isCampaignInCurrentYear } from '@lib/services'
import { type FC, type MouseEventHandler, useState } from 'react'
import { type APISchedule } from '@shamaazi/mytennights'
import Time from '~/service/time'
import { DeleteEmailModal } from './DeleteEmailModal'
import { UpdateEmailModal } from '~/components/UpdateEmailModal'
import { UpdateDonorModal } from '~/components/UpdateDonorModal'

interface ScheduleOptionsCardProps {
  donorId: string
  schedule: APISchedule
  scheduleId: string
  toggleCancellation: MouseEventHandler
  toggleNight30: MouseEventHandler
  resendConfirmation: MouseEventHandler
  toggleAutoEnrollStatus: MouseEventHandler
  toggleGiftAidStatus: MouseEventHandler
}

export const ScheduleOptionsCard: FC<ScheduleOptionsCardProps> = ({ donorId, schedule, scheduleId, toggleCancellation, toggleNight30, resendConfirmation, toggleAutoEnrollStatus, toggleGiftAidStatus }) => {
  const downloadTaxReceipt = async (): Promise<void> => {
    const data = await ScheduleAPI.getTaxReceiptDocument(donorId, scheduleId)
    savePDF(`${scheduleId}.pdf`, data)
  }

  const [showEditEmail, setShowEditEmail] = useState(false)
  const [showDeleteEmail, setShowDeleteEmail] = useState(false)
  const [showUpdateDonor, setShowUpdateDonor] = useState(false)

  const onClosePopup = (): void => {
    setShowEditEmail(false)
    setShowUpdateDonor(false)
    setShowDeleteEmail(false)
    window.location.reload()
  }
  const cancelItem = schedule.actionable
    ? <ListGroupItem>
      <Button variant="outline-danger" onClick={toggleCancellation}>
        {schedule.cancelled ? 'Reactivate schedule' : 'Cancel remaining donations'}
      </Button>
    </ListGroupItem>
    : <></>

  const night30Button = schedule.actionable
    ? <Button variant="outline-primary" onClick={toggleNight30}>
      Opt {schedule.night_30 ? ' out' : ' in'}
    </Button>
    : <></>

  const cardExpiry = schedule.card_expiry_year && schedule.card_expiry_month
    ? <><strong>Expires: </strong>{Time.formatCardExpiry(schedule.card_expiry_month, schedule.card_expiry_year)}</>
    : <></>

  return (
    <> <Card className="my-3 rounded shadow-lg">
      <ListGroup className="list-group-flush">
        <ListGroupItem>
          <strong>Paying with: </strong>{schedule.card_brand} ending {schedule.last_4_card_digits}
          <br />
          {cardExpiry}
        </ListGroupItem>
        <ListGroupItem>
          Opted
          <strong>{schedule.gift_aid ? ' ' : ' not '}</strong>
          to gift aid the donation
          <br />
          {isCampaignInCurrentYear(schedule.campaign) && <Button variant="outline-primary" onClick={toggleGiftAidStatus}>
            Opt {schedule.gift_aid ? ' out' : ' in'}
          </Button>}
        </ListGroupItem>
        <ListGroupItem>
          <div>{schedule.is_matched ? 'This donation is matched' : 'This donation is not matched'}</div>
        </ListGroupItem>
        {cancelItem}

        {isMyTenNights(schedule) && <ListGroupItem>
          Opted
          <strong>{schedule.night_30 ? ' ' : ' not '}</strong>
          to donate if Ramadan runs for 30 nights.
          <br />
          {night30Button}
        </ListGroupItem>}
        <ListGroupItem>
          Opted
          <strong>{schedule.auto_enroll ? ' ' : ' not '}</strong>
          to auto enroll for next year schedule
          <br />
          {<Button variant="outline-primary" onClick={toggleAutoEnrollStatus}>
            Opt {schedule.auto_enroll ? ' out' : ' in'}
          </Button>}
        </ListGroupItem>
        <ListGroupItem>
          <Button variant="outline-primary" onClick={resendConfirmation}>
            Resend confirmation email
          </Button>
        </ListGroupItem>
        {schedule.tax_receipt_number && <ListGroupItem>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Button variant="outline-primary" onClick={downloadTaxReceipt}>
            Download tax receipt
          </Button>
        </ListGroupItem>}
        <ListGroupItem>
            This action will update the donor details.
            <Button variant="outline-primary" onClick={() => setShowUpdateDonor(true)}>
              Update Donor Details
            </Button>
          </ListGroupItem>
          <ListGroupItem>
            This action will update only the donor email Id.
            <Button variant="outline-primary" onClick={() => setShowEditEmail(true)}>
              Update Donor Email
            </Button>
          </ListGroupItem>
          <ListGroupItem>
            <Button variant="outline-danger" onClick={() => setShowDeleteEmail(true)}>
              Delete Donor Details
            </Button>
          </ListGroupItem>
      </ListGroup>
    </Card>
      <UpdateDonorModal donorId={donorId} scheduleId={schedule.schedule_id} show={showUpdateDonor} donorDetails={schedule.donor_details} handleClose={onClosePopup} />
      <UpdateEmailModal donorId={donorId} show={showEditEmail} fromEmail={schedule.donor_details.email} handleClose={onClosePopup} />
      <DeleteEmailModal donorId={donorId} show={showDeleteEmail} email={schedule.donor_details.email} handleClose={onClosePopup} />
    </>
  )
}
