import 'bootstrap/dist/css/bootstrap.min.css'
import './index.scss'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { getShamaaziEnvironmentFromURL, initSentryInBrowser, ShamaaziEnvironment } from '@lib/services'

// Figure out environment from URL
const environment: ShamaaziEnvironment | undefined = getShamaaziEnvironmentFromURL()

// Prevent search engine spiders from crawling the website
if (environment !== ShamaaziEnvironment.production) {
  const meta = document.createElement('meta')
  meta.name = 'robots'
  meta.content = 'noindex'
  document.querySelector('head')?.appendChild(meta)
}

// Initialize Sentry (crash analytics)
const dsn: string = 'https://2211315668328fe64490e7b51f3e94c9@o4505828020125696.ingest.sentry.io/4506065927602176'
// - control which URLs distributed tracing should be enabled for
const tracePropagationTargetsStaging = [/^https:\/\/.+\.customerservice\.shamaazi\.io\/api/] // private envs & staging
const tracePropagationTargetsProduction = ['https://customerservice.shamaazi.com/api']
initSentryInBrowser(environment, dsn, tracePropagationTargetsStaging, tracePropagationTargetsProduction)

const container = document.getElementById('root')
createRoot(container!).render(
  <StrictMode>
    <App />
  </StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
