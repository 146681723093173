import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'

export default ({ query, campaign, onSubmit, campaigns }) => {
  const [loading, setLoading] = useState(false)
  const [searchQuery, setQuery] = useState(query ?? '')
  const [searchCampaign, setSearchCampaign] = useState(campaign)
  const generateCampaignAbbreviation = (campaignName) => {
    const year = campaignName.match(/\d{4}/);
    if (year) {
      const campaignType = campaignName.includes("Nights") ? "mtn" : "mtd";
      return `${campaignType}${year[0]}`;
    }
  };
  const campaignOptions = campaigns.map((campaignName, index) => {
    const abbreviation = generateCampaignAbbreviation(campaignName);
    return (
      <option key={index} value={abbreviation}>
        {campaignName}
      </option>
    );
  });

  const submit = async (event) => {
    event.preventDefault()
    setLoading(true)
    await onSubmit(searchQuery, searchCampaign)
    setLoading(false)
  }

  return <>
    <Form onSubmit={submit} className='flex flex-col gap-y-4'>
      <Form.Group>
        <Form.Control
          type="search"
          placeholder="Search"
          required
          id="search"
          pattern=".*[a-zA-Z0-9]+.*" // at least one alphanum
          value={searchQuery}
          onChange={e => setQuery(e.target.value)}
        />
        {campaigns.length > 0 && <Form.Control
          as="select"
          className="mt-3"
          onChange={e => setSearchCampaign(e.target.value)}
          defaultValue={campaign}
        >
          <option value="">All</option>
          {campaignOptions}
        </Form.Control>}
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
        className='w-1/4'
        disabled={loading || (!searchQuery)}
      >
        Search
      </Button>
    </Form>
  </>
}
