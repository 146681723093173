import { callService } from '@lib/services'
import { HttpRequestMethod } from 'http-core-constants'

export interface UpdateEmailData {
  donor_id: string
  from: string
  to: string
  type: string
  unique_reference: string
}

export interface DeleteEmailData {
  donor_id: string
  email: string
  type: string
  unique_reference: string
}

export const updateDonorEmail = async (data: UpdateEmailData): Promise<void> => {
  return callService(`/api/donor/${data.donor_id}/update_email`, HttpRequestMethod.POST, data)
}

export const deleteDonorEmail = async (data: DeleteEmailData): Promise<void> => {
  return callService(`/api/donor/${data.donor_id}/delete_email`, HttpRequestMethod.POST, data)
}
