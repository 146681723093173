import { Alert, Card, Row } from 'react-bootstrap'

export default ({ preferences, activeCharity, error, updatePreference, email }) => {
  return <>
    <h3 className="mt-4">Marketing Preferences</h3>
    <hr />
    <Row>
      {error && <Alert variant="danger __my-0">{error}</Alert>}
    </Row>
    <Card className="rounded shadow">
      <Card.Body>
        <label className="mx-0 mt-2 align-items-center">
          <input
            className="mr-4 mx-2"
            type="checkbox"
            onChange={(e) => {
              updatePreference({
                transport: 'email',
                identifier: email,
                channel: 'updates',
                source: 'mytennights',
                value: e.target.checked
              })
            }}
            checked={preferences?.email?.channels?.mytennights?.updates ?? false}
          />
          Be the first to know about MyTenNights' causes, updates and news
        </label>
      </Card.Body>
      <hr />
      <Card.Body>
        <label className="mx-0 my-2 align-items-center">
          <input
            className="mr-4 mx-2"
            type="checkbox"
            onChange={(e) => {
              updatePreference({
                transport: 'email',
                identifier: email,
                channel: 'reminder',
                source: 'mytennights',
                value: e.target.checked,
              })
            }}
            checked={preferences?.email?.channels?.mytennights?.reminder ?? false}
          />
          Get a reminder to catch Laylutar Qadr every year
        </label>
      </Card.Body>

    </Card>
    <Card className="my-4 rounded shadow">
      <Card.Body>
        <Card.Title>Charity Preferences</Card.Title>
        <p>I am happy to be contacted by these charities with information on their projects, fundraising activities and appeals via email.</p>
        {Object.keys(preferences?.email.channels.charity ?? {}).map(charityID => {
          return <label key={charityID} className="mx-0 mt-4 align-items-center row">
            <input
              className="mr-4"
              type="checkbox"
              onChange={(e) => {
                updatePreference({
                  transport: 'email',
                  identifier: email,
                  channel: charityID,
                  source: 'charity',
                  value: e.target.checked
                })
              }}
              checked={preferences?.email?.channels?.charity[charityID] ?? false}
            />
            {preferences?.email.charity_names[charityID]}
          </label>
        })}
      </Card.Body>
    </Card>
  </>
}
